<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col id="driver" cols="12" sm="3" lg="3" xl="3" md="3">
        <v-card width="100%" elevation="0" color="#E5E5E5">
          <v-btn
            color="expansionPanels"
            elevation="0"
            v-for="btn in buttons"
            :key="btn.name"
            @click="setComponent(btn.cmp)"
            :class="{ active: btn.cmp === currentComponent }"
            :id="btn.id"
          >
            <v-icon color="black">{{ btn.icon }}</v-icon>
            {{ btn.name }}
          </v-btn>
          <v-btn color="expansionPanels" elevation="0" @click="runHelp()">
            <v-icon color="black">mdi-desktop-mac</v-icon>
            Help/Support
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="9" lg="9" xl="9" md="9">
        <keep-alive>
          <component :is="currentComponent"></component>
        </keep-alive>
      </v-col>
    </v-row>
    <v-tour name="briefTour" :steps="steps" :callbacks="tourCallbacks"></v-tour>
  </v-container>
</template>

<script>
import BriefsList from "@/components/briefs/BriefsList.vue";
import CreateBrief from "@/components/briefs/CreateBrief.vue";

export default {
  data() {
    return {
      currentComponent: "BriefsList",
      buttons: [
        {
          name: "Create Briefs",
          icon: "mdi-compass-outline",
          cmp: "CreateBrief",
          id: "v-step-0",
        },
        {
          name: "View Briefs",
          icon: "mdi-home",
          cmp: "BriefsList",
          id: "v-step-1",
        },
      ],

      steps: [
        {
          target: "#v-step-0",
          header: {
            title: "Create a brief",
          },
          content: `A brief is a request by a marketer for a possible launch of a campaign. Our planners will act on your brief to create the best Out-of Home solution`,
        },
        {
          target: "#v-step-1",
          header: { title: "View briefs" },

          content: "All briefs that you created are located here",
        },
        {
          target: "#v-step-2",
          header: { title: "Test create briefs" },

          content: "All briefs that you created are located here",
        },
      ],

      tourCallbacks: {
        onStart: this.onStart,
      },
    };
  },
  components: { BriefsList, CreateBrief },
  methods: {
    setComponent(cmp) {
      this.currentComponent = cmp;
    },
    runHelp() {
      this.$tours["briefTour"].start();
    },
    onStart(currentStep) {
      console.log(currentStep);
      if (currentStep === 0) {
        this.currentComponent = "CreateBrief";
      } else if (currentStep === 2) {
        this.currentComponent = "BriefsList";
      }
    },
  },
};
</script>

<style scoped>
#driver {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.v-btn {
  margin: 10px;
  height: 48px !important;
  width: 90%;
  text-transform: none;
}

.active {
  background: #f68625 !important;
}
</style>
